html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  position: relative;
  min-height: 100%;
}

body {
  line-height: 1.25rem;
  overflow-x: hidden;
}

b,
strong {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}


h1, .h1 {
  font-weight: 700;
}

.headings-color {
  color: $headings-color;
}

.text-15pt {
  font-size: .9375rem !important;
}