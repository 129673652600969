// Futura Fonts

@font-face {
  font-family: 'Futura';
  src: url('../../../fonts/Futura-Std-Book.otf');
  src: local('Futura Std'), local('Futura-Std'), url('../../../fonts/Futura-Std-Book.otf?#iefix') format('embedded-opentype');
  font-weight: 300;
  font-style: normal;
}


@font-face {
  font-family: 'Futura';
  src: url('../../../fonts/Futura-Book.otf');
  src: local('Futura Book'), local('Futura-Book'), url('../../../fonts/Futura-Book.otf?#iefix') format('embedded-opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Futura';
  src: url('../../../fonts/FuturaRound-Bold.otf');
  src: local('Futura Bold'), local('Futura-Bold'), url('../../../fonts/FuturaRound-Bold.otf?#iefix') format('embedded-opentype');
  font-weight: 700;
  font-style: normal;
}
