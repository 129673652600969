// //
// // tables.scss
// //

// //Table centered (Custom)
// .table-centered {
//   th, td {
//     vertical-align: middle !important;
//   }
// }


// // Custom table components (Custom)
.table {
  border:1px solid $border-color;

  .table-avatar {
    img {
      height: 30px;

    }
  }

  .action-icon {
    // color: #{map-get($grays, "600")};
    color: $text-muted;
    font-size: 1.2rem;
    display: inline-block;
    padding: 0 3px;

    &:hover {
      // color: #{map-get($grays, "700")};
      color: $primary;
    }
  }

  // &>:not(:first-child) {
  //     border: inherit;
  // }
  > :not(caption) > * > * {
    background-color: var(--#{$variable-prefix}table-bg) !important;
  }

  &.table-bordered {
    tbody {
      border-top: $border-width solid;
      border-top-color: inherit;
    }
  }
}

// // Tables fluid
// .table-nowrap {
//   th, td {
//     white-space: nowrap;
//   }
// }


// table {
//   // Table Hover
//   &.table-hover {
//     thead,
//     tbody {
//       tr {
//         &:hover {
//           > * {
//             --ct-table-accent-bg: #f1f3fa;
//           }
//         }
//       }
//     }
//   }

//   // Table Active
//   tr {
//     &.table-active {
//       --ct-table-accent-bg: #f1f3fa;
//     }
//   }

//   //  Table Striped
//   &.table-striped {
//     tbody {
//       tr {
//         &:nth-of-type(odd) {
//           > * {
//             --ct-table-accent-bg: #f1f3fa;
//           }
//         }
//       }
//     }
//   }
// }

.table thead th {
  font-size: .825rem;
  color: $table-head-color;
}
.table tbody td {
  vertical-align: middle;
}
.thead-border-top-0 thead th {
  border-top: 0;
}
.table tbody .selected > td {
  background-color: rgba(#5897E0, .05);
}

.table > :not(caption) > * > * {
  box-shadow: none;
}

.table .sort {
  color: inherit;
  display: flex-inline;
  align-items: center;
  text-decoration: none;
  
  &.asc,
  &.desc {
    color: $body-color;
    text-decoration: underline;
  }

  & + .sort {
    margin-left: 1rem;
  }
}


a.rating-link {
 color: theme-color("light-gray");
 &:hover, &.active {
   color: theme-color("warning");
 }
}

.table thead th {
  vertical-align: middle;
  .dropdown-toggle { 
    text-decoration: none;
    &:hover {
      color: $body-color;
    }
  }

  .table-striped tr:hover { 
    background: rgba(0,0,0, .03); 
  }
}


table thead {
  &, a {
  font-weight: bold;
  text-decoration: none;
  text-align: left;
  font-size: 13px;;
  }
  a:hover, a.current-sort {
    color: #15283D;
  }
}

$table-head-color: rgba(#374D67, .54);

table thead {
  a { 
    display: block;
    width: 100%;
  }
  
  a.sort-by { 
    padding-right: 18px;
    position: relative;
  }
  a.sort-by:before,
  a.sort-by:after {
    border: 4px solid transparent;
    content: "";
    display: block;
    height: 0;
    right: 5px;
    top: 50%;
    position: absolute;
    width: 0;
  }
  a.sort-by:before {
    border-bottom-color: $table-head-color;
    margin-top: -9px;
  }
  a.sort-by:after {
    border-top-color: $table-head-color;
    margin-top: 1px;
  }

  a.sort-by.desc  {
    &:after { border-top-color: $body-color;}
    // &:before { border-bottom-color: $border-color;}
  }
  a.sort-by.asc  {
    // &:after { border-bottom-color:  $border-color;}
    &:before { border-bottom-color: $body-color;}
  }
}
