// Prefix for :root CSS variables

// Color system
$body-bg: #f9f9f9 !default;
$body-color: #212121 !default;
$link-color: #4a90e2 !default;
$text-muted: rgba(#212121, .54) !default;
$white: #ffffff !default;
$black: #000000 !default;
// Spiffy Colors
$primary: #3A76BA !default;
$spiffy-blue-light: $primary !default;
$spiffy-blue-dark: darken($primary, 10%) !default;
$primary-dark: darken($primary, 10%) !default;

$success: #5BBA76 !default;
$dark: #14426F !default;
$info: #51A7C5 !default;
$warning: #E0CA3C !default;
$danger: #c44b4f !default;

$secondary: #787878 !default;

// scss-docs-start color-variables
$blue: #2c8ef8;
$indigo: #727cf5;
$purple: #6b5eae;
$pink: #ff679b;
$red: #a63d40;
$orange: #fd7e14;
$yellow: #ffbc00;
$green: #5BBA76;
$teal: #02a8b5;
$cyan: #39afd1;

$dark-gray: #939FAD !default;
$light-gray: #ECEEF0 !default;
$light: #f8f9fa !default;

// scss-docs-end color-variables

// scss-docs-start colors-map
$colors: (
  "blue": $blue,
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "red": $red,
  "orange": $orange,
  "yellow": $yellow,
  "green": $green,
  "teal": $teal,
  "cyan": $cyan,
  "white": $white,
  "light": $light
);
// scss-docs-end colors-map

// scss-docs-start theme-color-variables
// $primary: $spiffy-blue-light;
// scss-docs-end theme-color-variables

// scss-docs-start theme-colors-map
$theme-colors: (
  "primary": $primary,
  "primary-dark": $primary-dark,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "dark": $dark,
  "light": $light
);
// scss-docs-end theme-colors-map


$small-font-size: .8125rem !default;
$headings-color: rgba($body-color, .84) !default;
$bottom-spacing: 0.9375rem * 2 !default;
$border-color: #efefef !default;
$badge-border-radius: 2px !default;

$bg-topbar: $dark !default;
$bg-topnav: $bg-topbar !default;
$bg-dark-topbar: $dark !default;
$bg-topbar-search: darken($bg-topbar,15) !default;
$bg-dark-topbar-search: $bg-topbar-search !default;
$nav-user-bg-dark-topbar: rgba(0,0,0,.3) !default;
$nav-user-border-dark-topbar: none !default;

$bg-leftbar: #ffffff !default; 
$bg-leftbar-gradient: #ffffff !default;

// scss-docs-start theme-colors-rgb
// $theme-colors-rgb: map-loop($theme-colors,  "$value");
// scss-docs-end theme-colors-rgb

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio: 1.8 !default;

// Customize the light and dark text colors for use in our color contrast function.
// $color-contrast-dark: $gray-900;
// $color-contrast-light: $white;

// Characters which are escaped by the escape-svg function
$escaped-characters: (
  ("<", "%3c"),
  (">", "%3e"),
  ("#", "%23"),
  ("(", "%28"),
  (")", "%29"),
);

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret: true;
$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;
$enable-transitions: true;
$enable-reduced-motion: true;
$enable-smooth-scroll: true;
$enable-grid-classes: true;
$enable-container-classes: true;
$enable-cssgrid: false;
$enable-button-pointers: true;
$enable-rfs: true;
$enable-validation-icons: true;
$enable-negative-margins: true;
$enable-deprecation-messages: true;
$enable-important-utilities: false;

$text-muted: rgba($body-color, .84) !default;

.text-muted {
 color: $text-muted !important;
}

// Gradient
//
// The gradient which is added to components if `$enable-gradients` is `true`
// This gradient is also added to elements with `.bg-gradient`
// scss-docs-start variable-gradient
$gradient: linear-gradient(180deg, rgba($white, .15), rgba($white, 0));
// scss-docs-end variable-gradient

$body-font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, "Lucida Grande", Arial, sans-serif !default;
$body-font-style: normal !default;


// stylelint-enable value-keyword-case
$font-family-base: $body-font-family;
// $font-family-code: var(--#{$variable-prefix}font-monospace);

// $font-size-root affects the value of `rem`, which is used for as well font sizes, paddings, and margins
// $font-size-base affects the font size of the body text
$font-size-root: 16px;
$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-size-sm: $font-size-base - .025;
$font-size-lg: $font-size-base * 1.390;



// DARK

// Dark Grays
$dark-grays: ("100": #404954,
  "200": #37404a,
  "300": #464f5b,
  "400": #8391a2,
  "500": #aab8c5,
  "600": #ced4da,
  "700": #dee2e6,
  "800": #e3eaef,
  "900": #f1f1f1,
);


$btn-border-radius: 3px;
$btn-padding-x: 14px;
$btn-padding-y: 6px;


$menu-item: #bbb !default;
$menu-item-hover: #212121 !default;
$menu-item-active: #212121 !default;
$rightbar-bg: #ffffff !default;
$rightbar-width: 250px !default;
$table-bg: #ffffff !default;
$card-header-border:  $border-color !default;

$table-head-color: rgba(#212121, .54) !default;

