// 
// fleet-dashboard.scss
//

.fleet-dashboard-card {
  border-radius: 50px;
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: start;


  &-top {
    // background-color: $gray-400;
    font-weight: bolder;
    width: 100%;
    height: 100%;
    padding: 1em;
    border-radius: inherit;
  }


}


.fleet-branch-card {
  display: flex;
  justify-content: space-between;
}